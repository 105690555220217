import React, { Component } from 'react';

class PrivacyPolicy extends Component {

    render() {
      return (
        <div class="container margin-privacy">
		

			

		

	
		
<h1 class="entry-title margin-card text-dark">PRIVACY POLICY</h1>

	
	
	<div class="entry-content">
		
<p  className="text-dark">Your Data is private.&nbsp;Only you have access.</p>



<h5 className="text-dark">Privacy of Customer Data</h5>



<p className="text-dark"><a href="https://www.welfund.org/">www.welfund.org</a> is a product by  Welfund Climate Technology Advisory  Pvt. Ltd. (called
Welfund from here onwards)</p>



<p className="text-dark">At Welfund, we place
the highest importance on respecting and protecting the privacy of our
customers. Our relationship with you is our most important asset. We want you
to feel comfortable and confident when using our products and services and with
entrusting your personal and company information to us.</p>



<h5 className="text-dark">Scope</h5>



<p className="text-dark">This statement applies to the Welfund Web sites and services that display or link to this statement.</p>


<h5 className="text-dark">Information Collection</h5>

<ul className="text-dark font-light"><li>When you purchase a Welfund product or service, we ask you for information such as name, address, and telephone number that helps us confirm that your payment information is correct. This information is used to create or maintain your Welfund account information.</li><li> When you register, we require that you create user credentials consisting of a user id and password. We also collect an e-mail address so that we may send communications such as order confirmation or information about the status of your return. </li><li> When you visit our Web sites we may collect information about the pages you view, the links you select or actions you take using the standard information that your browser sends to each Web site you visit such as IP address, browser type, and the previously visited Web page. </li></ul>



<h5 className="text-dark">Information Use</h5>



<ul className="text-dark font-light"><li> We do not sell or rent your personal or company information to anyone. </li><li> We do not share your personal or company information with anyone outside of Welfund for their promotional or marketing use. </li><li> We do not share your information inside of Welfund for promotional or marketing use. </li><li> Welfund uses your personal information to manage your account, to contact you and to operate, improve, and deliver our products and services, including Web sites. We use your personal information to provide you with information and services you request, to tell you about Welfund products and services, and to give you a customized, interactive experience as you use our products. We use your personal information to charge you for the products and services you purchase. We use the contact information you provide to communicate with you. We use your information to prepare and share your report, and provide related assistance and services. </li><li> We summarize information about your usage and combine it with that of others to learn about the use of Welfund products to help us develop new products and services. This information is collected in such a way that it cannot be used to identify an individual. </li><li> We may use service companies on our behalf to perform services for you but these companies are not allowed to use your information for their own purposes. </li><li> We may access and/or disclose your information if it is necessary to comply with the law or legal process, to protect or defend Welfund. For example, we may be required to cooperate with regulators or law enforcement action such as a court order, subpoena or search warrant. </li></ul>



<h5 className="text-dark">Information Security</h5>



<p className="text-dark">Welfund works to
protect your personal information and company information from loss, misuse or
unauthorized alteration by using industry-recognized security safeguards,
coupled with carefully developed security procedures and practices. We maintain
electronic and procedural safeguards of all company information. We use
both internal and external resources to review our security procedures.
Whenever we prompt you to transmit sensitive information, such as credit card
information, we support the encryption of your information as it is transmitted
to us. Our employees are trained and required to safeguard your information.</p>



<h5 className="text-dark">Changes to this Privacy Policy </h5>
        <p className="text-dark">Please note that this
Privacy Policy may change from time to time. We will post any Privacy Policy
changes on this page and, if the changes are significant, we will provide a
more prominent notice (including, for certain services, email notification of
Privacy Policy changes)</p>


	</div>


		
	</div>
   );
}
}
export default PrivacyPolicy;
