import React, { Component } from 'react';

class Faqs extends Component {

    render() {
      return (
        <div className="container faq ">
		<h1 className="entry-title margin-card text-dark" style={{marginBottom:'30px'}}>Frequently Asked Questions</h1>	
			<div className="entry-content" style={{marginBottom:'30px'}}>
				<h2>Investors</h2>			
				<h5>1.What type of projects are listed on the Welfund platform?</h5>
				<p>Welfund is presently working on funding on-grid and off-grid solar PV projects for homeowners, MSMEs, commercial, industrial and government clients located in India. </p>
				<h5>2.What is the return on investment in renewable projects?</h5>
				<p>Each project is unique in terms of risk and returns and our platform offers Post Tax Equity returns between 12-18%. We help investors gain access to low cost debt financing to boost project returns.</p>
				<h5>3. What is the minimum investment amount?</h5>
				<p>We presently facilitate direct investments into projects starting 100kWp onwards, requiring a minimum investment amount of $55,000. Our team handholds you from project selection, investment advisory, vendor selection and asset and revenue management during the project tenure.</p>
				<h5>4.What are risks associated with projects?</h5>
				<p>Project risks can be categorized into technical risks and consumer risks. Technical risks are associated with the technology used, generation estimation, contactor, and project performance. Financial risks are related to consumer default in payments.</p>
				<h5>5.How does Welfund help minimize and mitigate risks?</h5>
				<p>Welfund follows an intrinsic approach to mitigate the technical risks by controlling the technology selection and selecting reputed vendors backing the project with performance and generation guarantees and warranties. We minimize the consumer default risk by doing a comprehensive risk assessment with in-house developed WelfundScore to ensure credit worthy consumers are curated on our platform.</p>
				<h5>6.How can I invest?</h5>
				<p>Get in touch with us to submit your interest and share your investment preferences. Our team will then send you qualified projects that meet your investment criteria. </p>
				<h5>7.What is Welfund's role?</h5>
				<p>Welfund brings curated projects meeting target investment returns and handholds investors in contractor selection, contracts, investment advisory and asset management for the entire duration of the investment. We are your long-term partners in your sustainable investment journey.</p>
				<h2>Consumers</h2>

				<h5>1.What are the financial benefits of solar?</h5>
				<p>When you install solar on your property, you generate your own power and save money on your electricity bills and protect yourself against rising costs of electricity. Our consumers typically save between 20%-60% on their energy bills depending on their electricity tariff and mode of financing. </p>
				<h5>2.What are my solar financing options?</h5>
				<p>Discover tailored make funding options for your project – depending on your eligibility we offer collateral free short term financing, low cost terms loans or long term Power Purchase Agreement requiring no upfront investment.</p>

				<h5>3.Which funding option is best for me?</h5>
				<p>The decision to buy or fund your project depends on your reasons for going solar. If you are interested in maximizing your financial returns and claiming accelerated depreciation benefit, buying the system and short term lease are the best options in that order. However, if you are looking at putting up no upfront costs and enjoying maintenance free way to reduce your energy spending, you can consider solar PPAs. Get in touch to receive multiple funding offers for your project.</p>

				<h5>4.What is the cost of financing for solar projects?</h5>
				<p>The cost of financing for short term lease (3-7 years) ranges from 12-15%, while the cost of financing for special long term loans ranges from 8.5% to 11%. While all lenders have collateral free schemes for financing projects, not all consumers may be eligible for this benefit. </p>
				<h5>5.How do I find the most qualified installation partner for my project?</h5>
				<p>The Welfund team has expertise in funding over 300MW of projects and can help you discover the most qualified installation contractors for your project providing high quality equipment and guarantees to back their claims. Get in touch with us to know more about how you can receive multiple quotes for your project.</p>
				<h5>6.What geographies does Welfund cover for project funding?</h5>
				<p>We are presently operational in India. </p>

				<h5>7.How can I partner with Welfund to help my customers get funding?</h5>
				<p>We work with EPC partners and Energy Consultants to help discover funding for their clients. To find out more about how you can partner with us, contact us <a href="https://project.welfund.org/login"> here.</a></p>
			</div>		
		</div>
	   );
	}
}
export default Faqs;
